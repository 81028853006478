import { Stats } from "../interfaces/Stats";

export const initialStats: Stats = {
    played: 0,
    win: 0,
    winPercent: 0,
    currentStreak: 0,
    gameNumber: 0,
    lastWinDate: "",
    maxStreak: 0,
    guessDist: [0, 0, 0, 0],
    guesses: ""
  };