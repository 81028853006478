import { initialStats } from "../data/stats";
import { Stats } from "../interfaces/Stats";
import { saveStats } from "./database";
import { getGameNumber } from "./utilities";
import { UserDetails } from "../interfaces/UserDetails";

function hasSavedToday(): boolean {
    const lastSaved = localStorage.getItem('lastSaved');

    if (!lastSaved) {
        return false;
    }

    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    
    return formattedToday === lastSaved;
}

function setLastSaved() {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    localStorage.setItem('lastSaved', formattedToday);
}

export async function saveStatsToDB(userDetails: UserDetails): Promise<UserDetails | null> {
    if (!userDetails.loggedIn || hasSavedToday()) {
        return null;
    }

    try {
        setLastSaved();
        const stats = calculateStats();
        const user = await saveStats(stats);
        console.log("User details after saving stats: ", user);
        if(user.data.success && user.data.user) {
            console.log(user.data.user);
            return user.data.user;
        }
        return null;
    } catch (error) {
        console.error("An error occurred while saving stats: ", error);
        return null;
    }
}

export function calculateStats() {

    const guesses: Record<string, { movieGuessed: boolean; invalidGuessIds: string[] } | undefined> = JSON.parse(localStorage.getItem('guesses') ?? "{}");
    let newStats: Stats = JSON.parse(JSON.stringify(initialStats));
    const gameNumber = getGameNumber();
    newStats.gameNumber = gameNumber;
    let currentStreak = 0;
    let maxStreak = 0;

    // Sort the keys of the guesses object
    const sortedKeys = Object.keys(guesses).sort();

    sortedKeys.forEach(date => {
        if (!guesses[date]?.movieGuessed && (guesses[date]?.invalidGuessIds.length !== 3)) {
            return;
        }
        newStats.played++;
        if (guesses[date]?.movieGuessed) {
            newStats.win++;
            if (newStats.lastWinDate < date) {
                newStats.lastWinDate = new Date(date).toISOString();
            }
            currentStreak++;
            if (currentStreak > maxStreak) {
                maxStreak = currentStreak;
            }
        } else {
            currentStreak = 0;
        }

        const invalidGuessCount = guesses[date]?.invalidGuessIds.length ?? 0;
        newStats.guessDist[invalidGuessCount]++;
    });

    newStats.winPercent = (newStats.win / newStats.played) * 100;
    newStats.currentStreak = currentStreak;
    newStats.maxStreak = maxStreak;
    newStats.guesses = JSON.stringify(guesses);

    return newStats;
}