import { Link } from "react-router-dom";
import { pickRandomMovie } from "../../movies";
import { useCallback, useMemo, useState } from "react";
import { MovieCard } from "../../components/MovieCard";
import { Twemoji } from "react-emoji-render";
import { Picker } from "emoji-mart";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { buildShareUrl } from "../../services/utilities";

export function MakeAEmoviRoute() {
    const [movieToGuess, setMovieToGuess] = useState(pickRandomMovie());
    const [emojiText, setEmojiText] = useState("");
    const [validated, setValidated] = useState(false);

    const shareUrl = useMemo(
        () => buildShareUrl({ id: movieToGuess.id, emojiText }),
        [emojiText, movieToGuess.id]
    );

    const handleNewEmovi = useCallback(() => {
        setMovieToGuess(pickRandomMovie());
        setEmojiText("");
        setValidated(false);
    }, []);

    const shareText = useMemo(() => {
        return [
            "#Emovi 🎬 #MyEmovi",
            "Guess this movie:",
            emojiText,
            shareUrl,
        ].join("\n");
    }, [emojiText, shareUrl]);

    return (
        <div className="w-full">
            <div className="flex flex-col items-center gap-2 w-full">
                <MovieCard movie={movieToGuess} />
                {!validated && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => {
                            setMovieToGuess(pickRandomMovie());
                        }}
                    >
                        Pick another movie...
                    </button>
                )}
            </div>
            <div className="flex flex-col gap-1 items-center">
                <div className="flex flex-col gap-2 justify-center items-center grow shrink-0 basis-32">
                    <p className="text-xl text-center">
                        {emojiText ? (
                            <Twemoji
                                text={emojiText}
                                options={{ className: "inline-block text-3xl" }}
                            />
                        ) : (
                            <span> Describe movie with emoji!</span>
                        )}
                    </p>
                    {emojiText && !validated && (
                        <div className="flex gap-2">
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => {
                                    setEmojiText("");
                                }}
                            >
                                Clear
                            </button>
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => setValidated(true)}
                            >
                                Validate
                            </button>
                        </div>
                    )}
                </div>
                {!validated ? (
                    <Picker
                        set="twitter"
                        showSkinTones={false}
                        showPreview
                        onSelect={(e: any) => {
                            setEmojiText((prev) => prev + e.native);
                        }}
                    />
                ) : (
                    <div className="flex flex-col gap-1 w-full text-center">
                        <CopyToClipboard
                            text={shareText}
                            onCopy={() => toast("Emovi copied to clipboard")}
                            options={{
                                format: "text/plain",
                            }}
                        >
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                Share my Emovi
                            </button>
                        </CopyToClipboard>
                        <button
                            onClick={handleNewEmovi}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Create another Emovi
                        </button>
                        <Link
                            to="/"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Go to the daily Emovi
                        </Link>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 font-bold text-center py-4"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSevIwncXHGD4K4yPBHi3Z2P0ynzmqaWZccfCCnzitN5xei54g/viewform?usp=sf_link&hl=en"
                        >
                            Suggest a daily Emovi
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}
