import dayjs from "dayjs";
import { START_DATE, getDayString } from "../../services/utilities";
import { DateTime, Interval } from "luxon";
import { DAILY_EMOVI } from "../../data/games";
import { GuessAEmovi } from "../../components/GuessAEmovi";
import { Link } from "react-router-dom";

export function DailyEmoviRoute() {

    const curerntString = getDayString();
    const dayString = dayjs(curerntString).format('YYYY-MM-DD');

    const dailyNumber =
        Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
            "day"
        ) + 1;
    const emoviToGuess = DAILY_EMOVI[dayString];
    return emoviToGuess ? (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold text-lg">Emovi #{dailyNumber}</p>
            <GuessAEmovi
                emoviToGuess={emoviToGuess}
                dailyNumber={dailyNumber}
                dayString={dayString}
            />
        </div>
    ) : (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold">No daily emovi for today!</p>
            <p className="text-center">
                <Link
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    to="/make"
                >
                    Create your own Emovi
                </Link>{" "}
                or check{" "}
                <a
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    href="https://twitter.com/search/?q=%23MyEmovi"
                >
                    #MyEmovi
                </a>{" "}
                on Twitter!
            </p>
        </div>
    );
}