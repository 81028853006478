import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../UserProvider";
import { clearCookie } from "../../services/cookies";
import { initialState } from "../../data/userDetailsInitial";

export const Account = () => {

    const { userDetails } = useContext(UserContext);

    return (
        <>
            <div className="px-4 md:px-0">
                <div className="mb-4 mt-4">
                    <Link to="/">
                        <span className="text-blue-500 hover:text-blue-400">
                            &#8592; Back
                        </span>
                    </Link>
                </div>
                <h2 className="text-xl font-bold">Your account</h2>
                {userDetails.loggedIn ? <AccountDashboard /> : <SignIn />}
            </div>
        </>
    )
}

export const SignIn = () => {

    const navigate = (path: string) => {
        window.location.href = `https://account.teuteuf.fr/${path}`
    }

    return (
        <>
            <div >
                <p className="mt-3">Create a <a href="https://teuteuf.fr" className="text-blue-500 hover:text-blue-400 cursor-pointer" rel="noreferrer" target="_blank">Teuteuf Games</a> or log in to start saving your gaming statistics. Want to know the benefits? {' '}
                    <a href="https://account.teuteuf.fr/benefits" className="text-blue-500 hover:text-blue-400 cursor-pointer">Discover more here</a>.
                </p>
                <div className="flex gap-2 mt-4">
                    <button onClick={() => navigate('sign-up')}
                        className="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Sign up</button>
                    <button onClick={() => navigate('login')}
                        className="border border-slate-400 !text-black font-bold py-2 px-4 rounded">Login</button>
                </div>
            </div>
        </>
    )
}

export const AccountDashboard = () => {

    const { userDetails, setUserDetails } = useContext(UserContext);
    const navigate = useNavigate();

    const handleSignOut = () => {
        clearCookie('cookieName');
        setUserDetails(initialState);
        localStorage.removeItem('emovi-user');
        navigate('/');
    }

    return (
        <>
            <div>
                <p className="mt-3">Hi {userDetails.firstName} 👋</p>
                <p className="mt-2">We now store your stats after each game. This not only safeguards your progress but also enables
                    seamless gameplay across multiple devices. <a href="https://account.teuteuf.fr/benefits" className="text-blue-500 hover:text-blue-400 cursor-pointer">Learn
                        more</a>.</p>

                <div className="bg-amber-100 shadow-md rounded-md p-3 mt-4 mb-6 text-black">
                    <h5 className="font-bold">Want to play previous games?</h5>
                    <p className="mt-2">Want to play previous games of Emovi? All the way back to the very first game?</p>
                    <div className="flex gap-1 flex-col md:flex-row">
                        <button onClick={() => navigate('/archive')}
                            className="border bg-neutral-100 border-black rounded-md text-black font-bold py-2 px-4 shadow-md mt-2">Play the
                            archive</button>
                        <button onClick={() => { navigate("/replay") }} className="border bg-neutral-100 border-black rounded-md text-black font-bold py-2 px-4 shadow-md mt-2">Replay
                            today's game</button>
                    </div>
                </div>

                <div className="flex gap-2 mt-4 items-center justify-center">
                    <button onClick={handleSignOut} className="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Sign out</button>
                    <a href="https://account.teuteuf.fr" className="border border-slate-400 text-black font-bold py-2 px-4 rounded">Manage account</a >
                </div >
            </div >
        </>
    );
}
