export function getCookie(name: string) {
    if (!document.cookie) {
        return null;
    }

    const ca: Array<string> = document.cookie.split(';');
    const cookieName = `${name}=`;

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();

        if (c.indexOf(cookieName) === 0) {
            return c.substring(cookieName.length);
        }
    }

    return null;
}

export function clearCookie(name: string): void {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.teuteuf.fr;";
}