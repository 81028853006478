import base64 from "base-64";
import dayjs from "dayjs";
import { DateTime, Interval } from "luxon";
import utf8 from "utf8";
import { EmoviToGuess } from "../interfaces/EmoviToGuess";

export const START_DATE = DateTime.fromISO("2022-07-17");

export function getDayStringNegOne() {
  const date = new Date();
  date.setDate(date.getDate());
  const dateString = date.toISOString().split('T')[0]; 
  return dateString;
}


export const checkPhotoValidity = (photoURL: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = photoURL;
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
    });
  };

  export function getGameNumber() {
    console.log('getting game numbner....')
    const curerntString = getDayStringNegOne();
    const dayString = dayjs(curerntString).subtract(1, 'day').format('YYYY-MM-DD');
    const dailyNumber =Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
        "day"
    ) + 1;
    return dailyNumber;
  }

  export function hasPlayedToday(guessesString: string) {
    // Parse the string into an object
    const guesses = JSON.parse(guessesString);
  
    // Get current date
    const today = new Date();

    // Subtract one day from today
    const yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
  
    // Extract the year, month, and date from yesterday
    const year = yesterday.getFullYear();
    const month = yesterday.getMonth();
    const date = yesterday.getDate();

    return Object.keys(guesses).some(dateString => {
      const guessDate = new Date(dateString);
      return guessDate.getFullYear() === year 
          && guessDate.getMonth() === month
          && guessDate.getDate() === date;
    });
}

export function isSameUtcDay(refreshTimeString: string): boolean {
  // Parse the refreshTime as a Date object
  const refreshDate = new Date(refreshTimeString);

  // Get the current date in UTC
  const currentDate = new Date();

  // Compare the year, month, and day
  return refreshDate.getUTCFullYear() === currentDate.getUTCFullYear()
    && refreshDate.getUTCMonth() === currentDate.getUTCMonth()
    && refreshDate.getUTCDate() === currentDate.getUTCDate();
}

export function getSavedGuesses() {
  const guesses: Record<string,
      { movieGuessed: boolean; invalidGuessIds: string[] } | undefined> = JSON.parse(localStorage.getItem('guesses') ?? "{}");

  return guesses;
}

export function decode(s: string) {
    return JSON.parse(utf8.decode(base64.decode(decodeURIComponent(s))));
}

export function encode(o: any) {
    return encodeURIComponent(base64.encode(utf8.encode(JSON.stringify(o))));
}

export function buildShareUrl(emoviToGuess: EmoviToGuess) {
    const encodedEmovi = encode(emoviToGuess);
    return `${window.location.origin}/guess/${encodedEmovi}`;
}

export function getDayString(shiftDayCount?: number) {
    return DateTime.now()
        .plus({ days: shiftDayCount ?? 0 })
        .toFormat("yyyy-MM-dd");
}


const PromoDateDiff = (dt1: any, dt2 = new Date()) => {
    const ret = { days: 0, months: 0, years: 0 };

    if (dt1 === dt2) return ret;
    if (dt1 > dt2) {
        const dtmp = dt2;
        dt2 = dt1;
        dt1 = dtmp;
    }

    const year1 = dt1.getFullYear();
    const year2 = dt2.getFullYear();

    const month1 = dt1.getMonth();
    const month2 = dt2.getMonth();

    const day1 = dt1.getDate();
    const day2 = dt2.getDate();
    ret["years"] = year2 - year1;
    ret["months"] = month2 - month1;
    ret["days"] = day2 - day1;

    if (ret["days"] < 0) {
        const dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);

        const numDays = dtmp1.getDate();

        ret["months"] -= 1;
        ret["days"] += numDays;
    }

    if (ret["months"] < 0) {
        ret["months"] += 12;
        ret["years"] -= 1;
    }

    return ret;
};

interface PromoProps {
    label: string;
    link: string;
}

export const getPromotionalInfo = (): PromoProps => {
    const PromotionalStartDate = new Date(2022, 10, 1);

    const PromotionalInfo = [
        {
            link: "https://semantle.com/junior",
            label: "Try Semantle Jr.!",
        },
        {
            link: "https://wheretaken.teuteuf.fr",
            label: "Try WhereTaken 🌎",
        },
        {
            link: "https://wheretakenusa.teuteuf.fr",
            label: "Try WhereTaken 🇺🇸",
        },
        {
            link: "https://www.thewordfinder.com/word-connection/",
            label: "Try Word Connection",
        },
        {
            link: "https://www.thewordfinder.com/daily-anagram/",
            label: "Try Daily Anagram",
        },
    ];
    const d = PromoDateDiff(PromotionalStartDate);
    let result = PromotionalInfo[0];
    if (d.months > 0) {
        const shuffledPromotions = PromotionalInfo.map((value) => ({
            value,
            sort: Math.random(),
        }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
        result = shuffledPromotions[0];
    }
    return result;
};