import { Navigate, useParams } from "react-router-dom";
import { GuessAEmovi } from "../../components/GuessAEmovi";
import { DAILY_EMOVI } from "../../data/games";
import { decode, getDayString } from "../../services/utilities";
import dayjs from "dayjs";
import { useMemo } from "react";
import { EmoviToGuess } from "../../interfaces/EmoviToGuess";

export function GuessAEmoviRoute() {
    const { emovi: encodedEmovi } = useParams<{ emovi: string }>();
    const emoviToGuess: EmoviToGuess | undefined = useMemo(
        () => (encodedEmovi ? decode(encodedEmovi) : undefined),
        [encodedEmovi]
    );


    const dayString = getDayString();
    const predayString = dayjs(dayString).subtract(1, 'day').format('YYYY-MM-DD');
    const dailyEmovi = DAILY_EMOVI[predayString];



    if (
        dailyEmovi &&
        emoviToGuess &&
        dailyEmovi.id === emoviToGuess.id &&
        dailyEmovi.emojiText === emoviToGuess.emojiText
    ) {
        return <Navigate to="/" />;
    }

    return emoviToGuess ? (
        <GuessAEmovi emoviToGuess={emoviToGuess} />
    ) : (
        <div>Oops...</div>
    );
}