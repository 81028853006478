import axios, { AxiosResponse } from 'axios';
import { Stats } from '../interfaces/Stats';

let SERVER_URL = "";

if (window.location.hostname.includes('staging')) {
  SERVER_URL = 'https://staging-auth.teuteuf.fr';
} else if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  SERVER_URL = 'http://localhost:3000';
} else {
  SERVER_URL = 'https://auth.teuteuf.fr';
}

export const refreshTokens = (): Promise<AxiosResponse<any>> => {
  return axios.post(`${SERVER_URL}/auth/refresh`, {}, { withCredentials: true });
};

export const getUserDetails = (): Promise<AxiosResponse<any>> => {
  return axios.get(`${SERVER_URL}/api/getuser`, { withCredentials: true });
};

export const saveStats = (stats: Stats): Promise<AxiosResponse<any>> => {
  const payload = {
    stats: JSON.stringify(stats),
    gameId: "emovi"
  }
  return axios.post(`${SERVER_URL}/api/savestats`, payload, { withCredentials: true });
};