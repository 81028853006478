import { Twemoji } from "react-emoji-render";
import "emoji-mart/css/emoji-mart.css";
import { useContext } from "react";
import {
    Link,
    Route,
    Routes,
} from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Account } from "./pages/Account/Account";
import UserContext, { UserProvider } from "./UserProvider";
import { StatsPage } from "./pages/Stats/Stats";
import { ImportSaveRoute } from "./pages/Import/Import";
import { ArchiveEmoviRoute } from "./pages/Archive/Archive";
import { getPromotionalInfo} from "./services/utilities";
import { DailyEmoviRoute } from "./pages/Daily/Daily";
import { MakeAEmoviRoute } from "./pages/Make/Make";
import { GuessAEmoviRoute } from "./pages/Guess/Guess";
import { ArchiveEmoviDateRoute } from "./pages/Archive/Date/Date";
import { ReplayRoute } from "./pages/replay/Replay";

const AppContent = () => {
    const { userDetails } = useContext(UserContext);

    const promotionalInfo = getPromotionalInfo();
    return (
        <>
            <header className="text-4xl font-bold text-center w-full border-b-2 border-gray-200 px-4">
                <div className="flex justify-between items-center w-full relative">
                    <Link to="/account">
                        <div className={`rounded-full ${userDetails.isPhotoValid ? 'w-7 h-7' : 'w-6 h-6'}`} style={{ width: 'max-content' }}>
                            <img
                                src={userDetails.isPhotoValid ? userDetails.photoURL : "/images/user.png"}
                                alt="user"
                                className="rounded-full"
                                style={{ display: 'block', margin: '0 auto', maxWidth: '100%', maxHeight: '100%' }}
                            />
                        </div>
                    </Link>
                    <div className="w-full my-1">
                        <Link to="/">
                            <Twemoji text="🎬 EMOVI 🎥" options={{ className: "inline-block" }} />
                        </Link>
                    </div>
                    <Link to="/stats">
                        <div className="w-6 h-6">
                            <img
                                src="/images/stats.png"
                                alt="stats"
                                style={{ display: 'block', margin: '0 auto', maxWidth: '100%', maxHeight: '100%' }}
                            />
                        </div>
                    </Link>
                </div>
            </header>
            <div className="flex justify-center items-center w-full pt-1 pb-1 lg:hidden xl:hidden md:hidden sm:visible" style={{ maxHeight: "50px" }}>
                <div id="adngin-top_banner-0"></div>
            </div>
            <div className="flex-grow w-full p-1">
                <Routes>
                    <Route path="/" element={<DailyEmoviRoute />} />
                    <Route path="/make" element={<MakeAEmoviRoute />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/stats" element={<StatsPage />} />
                    <Route path="/guess/:emovi" element={<GuessAEmoviRoute />} />
                    <Route path="/archive" element={<ArchiveEmoviRoute />} />
                    <Route path="/replay" element={<ReplayRoute />} />
                    <Route path="/archive/:date" element={<ArchiveEmoviDateRoute />} />
                    <Route path="/import/:save" element={<ImportSaveRoute />} />
                </Routes>
            </div>
            <footer className="flex justify-center flex-col mt-8 mb-4 gap-2"
                style={{ paddingBottom: "100px !important" }}>
                <div className="flex justify-center items-center">
                    <Twemoji
                        text="❤️ EMOVI 🎥 ?"
                        className="flex gap-1 items-center justify-center mr-1 font-bold" />{" "}
                    -
                    <a
                        className="underline pl-2"
                        href={promotionalInfo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="w-max">{"" + promotionalInfo.label}</div>
                    </a>
                </div>
            </footer>
        </>
    )
}

function App() {

    return (
        <UserProvider>
            <>
                <div className="flex justify-center flex-auto flex-row  relative overflow-hidden"
                    style={{ height: "100%", position: "relative" }}
                >

                    <div className="flex flex-col w-full max-w-lg relative">
                        <ToastContainer
                            hideProgressBar
                            position="top-center"
                            transition={Flip}
                            theme="light"
                            autoClose={2000}
                            bodyClassName="font-bold text-center"
                            toastClassName="flex justify-center m-2 max-w-full"
                            style={{ width: 500, maxWidth: "100%" }} />
                        <AppContent />
                        <div
                            className="absolute left-0 -translate-x-full snigel-sidev"
                            style={{ zIndex: 200, maxWidth: "330px" }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    zIndex: 10000,
                                    width: "300px",
                                    display: "flex",
                                    marginTop: "15px",
                                    marginRight: "30px",
                                    minHeight: "200px",
                                    justifyContent: "flex-end",
                                    pointerEvents: "all",
                                }}
                            >
                                <div id="adngin-sidebar_left-0"></div>
                            </div>
                        </div>
                        <div
                            className="absolute right-0 translate-x-full snigel-sidev"
                            style={{ zIndex: 200, maxWidth: "330px" }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    zIndex: 10000,
                                    marginTop: "15px",
                                    marginLeft: "30px",
                                    width: "300px",
                                    display: "flex",
                                    minHeight: "200px",
                                    justifyContent: "flex-start",
                                    pointerEvents: "all",
                                }}
                            >
                                <div id="adngin-sidebar_right-0"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </UserProvider>
    );
}

export default App;
