import { UserDetails } from "../interfaces/UserDetails";

export const initialState: UserDetails = {
    loggedIn: false,
    firstName: '',
    photoURL: '',
    isPhotoValid: false,
    marketingOptIn: '',
    wheretaken: '',
    wheretakenusa: '',
    worldle: '',
    flagle: '',
    statele: '',
    emovi: '',
    subscription: {
      autoRenew: false,
      isPremium: false,
      subscriptionEndDate: "null"
    }
  };