import { decompressFromEncodedURIComponent } from "lz-string";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function ImportSaveRoute() {
    const navigate = useNavigate();
    const { save: encodedSave } = useParams<{ save: string }>();
    useEffect(() => {
        if (!encodedSave) {
            return;
        }

        const save = decompressFromEncodedURIComponent(encodedSave);
        if (!save) {
            return;
        }

        localStorage.setItem('guesses', save);
        navigate("/");
    }, [encodedSave, navigate]);

    return null;
}